<template>
  <div class="orderfinding">
    <div class="BBg" :style="{ height: isheight }" @click="outwuye"></div>
    <!-- 订单查询筛选 -->
    <div class="flex_between bbe9s">
      <div class="title f-left p10">一般商机池</div>
    </div>
    <div class="flex_warp mt10 pb20 filterPr">
      <!-- 订单时间 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20">订单时间</span>
        <el-date-picker
          v-model="time1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="datePicker"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <!-- 城市筛选 -->
      <div class="blocks2 flex_start flex_items">
        <span class="mr20">城市筛选</span>
        <el-select
          v-model="citykind"
          placeholder="选择城市"
          :clearable="true"
          @change="getwhere(citykind)"
          class="littleBox w-140"
        >
          <el-option
            v-for="(item, index) in citys"
            :key="index"
            :label="item.city"
            :value="item.city_id"
          ></el-option>
        </el-select>
      </div>

      <!-- 录入人员 -->
      <div class="blocks2 flex_start flex_items">
        <span class="mr20">录入客服</span>
        <el-select
          v-model="client"
          placeholder="选择录入客服"
          :clearable="true"
          filterable
          class="littleBox w-150"
        >
          <el-option
            v-for="(item, index) in clients"
            :key="index"
            :label="item.username"
            :value="item.username"
          ></el-option>
        </el-select>
      </div>
      <!-- 状态 -->
      <div class="blocks2 flex_start flex_items">
        <span class="mr20">状态</span>
        <el-select
          v-model="state"
          placeholder="选择状态"
          :clearable="true"
          filterable
          class="littleBox w-150"
        >
          <el-option
            v-for="(item, index) in stateList"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <!-- 渠道 -->
      <div v-if="citykind != ''" class="blocks2 flex_start flex_items">
        <span class="mr20">渠道筛选</span>
        <el-select
          v-model="qudao"
          placeholder="选择渠道"
          :clearable="true"
          filterable
          class="littleBox w-150"
        >
          <el-option
            v-for="(item, index) in qudaos"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <!-- 筛选订单 -->
      <div class="blocks2 flex_items">
        <el-input
          placeholder="请输入内容"
          v-model="chosecon"
          class="input-with-select littleBox"
        >
          <el-select
            v-model="selectkind"
            slot="prepend"
            :clearable="true"
            placeholder="请选择类别"
            style="width: 120px"
          >
            <el-option label="联系人" value="contacts"></el-option>
            <el-option label="联系方式" value="telephone"></el-option>
            <el-option label="订单号" value="order_sn"></el-option>
            <el-option label="维修地址" value="addres"></el-option>
            <el-option label="微信号" value="weChat"></el-option>
          </el-select>
        </el-input>
      </div>
      <!-- 筛选 -->
      <div class="blocks2">
        <el-button
          type="primary"
          size="small"
          plain
          class="m-r-30"
          @click="getDataAgain(0)"
          >筛选</el-button
        >
        <el-button type="warning" size="small" plain @click="Popupbox('add')"
          >录入商机</el-button
        >
      </div>
    </div>
    <!-- 订单列表 -->
    <div class="t1 orderList">
      <div class="block flex_column">
        <!-- <div class="title f-center p10">订单列表</div> -->
        <div>
          <el-table
            :data="tableData"
            border
            v-loading="loading2"
            :cell-class-name="tableRowStyleName"
            :header-cell-style="{ color: '#252525' }"
          >
            <el-table-column prop="order_sn" label="订单号"></el-table-column>
            <el-table-column
              label="来源"
              prop="title"
              width="100"
            ></el-table-column>
            <el-table-column
              width="120"
              label="二级来源"
              prop="titles2"
            ></el-table-column>
            <el-table-column
              width="80"
              prop="contacts"
              label="联系人"
            ></el-table-column>
            <el-table-column
              prop="telephone"
              label="联系方式"
              width="120"
            ></el-table-column>
            <el-table-column prop="addres" label="维修地址"></el-table-column>
            <el-table-column
              prop="dispatchType"
              label="订单状态"
              width="80"
            ></el-table-column>

            <el-table-column label="添加微信" width="80">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.wechat"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="changewechat(scope.row.order_id, scope.row.wechat)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <el-dropdown>
                  <span class="el-dropdown-link lookMsg">
                    查看信息
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item @click.native="but(scope.row, 'tousu')"
                      >投诉</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="completion(scope.row)"
                      >完工回访</el-dropdown-item
                    > -->
                    <el-dropdown-item
                      @click.native="seeInfo(scope.row, 'chakan')"
                      >查看</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="edit(scope.row)"
                      v-if="scope.row.state != 1 && scope.row.state != 2"
                      >编辑</el-dropdown-item
                    >
                    <!-- <el-dropdown-item @click.native="but(scope.row, 'zhipai')"
                      >转派</el-dropdown-item
                    > -->
                    <!-- <el-dropdown-item
                      v-if="scope.row.state == 0"
                      @click.native="but(scope.row, 'pool')"
                      >转至商机池</el-dropdown-item
                    > -->
                    <!-- <el-dropdown-item @click.native="but(scope.row, 'quxiao')">
                      <span v-if="scope.row.state < 4">取消订单</span>
                      <span
                        v-if="
                          scope.row.state < 7 &&
                          (scope.row.state > 4 || scope.row.state == 4)
                        "
                        >退单</span
                      >
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="
                        scope.row.states == '已完工' ||
                        scope.row.states == '已完成'
                      "
                      @click.native="but(scope.row, 'shouhou')"
                      >售后</el-dropdown-item
                    > -->
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 页码 -->
        <div class="flex_start mt20 flex_items">
          <span class="demonstration mr20">
            总共
            <span class="f-red">{{ count }}</span
            >条数据,当前 <span class="f-red">{{ tableData.length }}</span
            >条
          </span>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :page-size="20"
            :total="count"
            layout="prev, pager, next, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <div v-if="isdiag">
      <el-dialog
        :visible.sync="dialogTableVisibleadd"
        width="80%"
        :close-on-click-modal="false"
        @open="diagopen()"
        @close="diagclose()"
      >
        <Add v-if="pagename == 'add'" @getDataAgain="getDataAgain"></Add>

        <Assign
          v-if="pagename == 'bianji'"
          @getDataAgain="getDataAgain"
        ></Assign>
        <AssignNew
          v-if="pagename == 'bianjiNew'"
          @getDataAgain="getDataAgain"
        ></AssignNew>
      </el-dialog>
    </div>
    <div v-if="isdiag">
      <!-- 取消原因 -->
      <el-dialog :visible.sync="dialogVisible3" width="30%">
        <div v-if="orderstate < 4">
          <div class="flex_center flex_items pb20 bold f18">取消原因</div>
          <el-checkbox-group
            v-model="cancles"
            class="flex_column"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox label="不做了" class="mt10"></el-checkbox>
            <el-checkbox label="价格比其它家高" class="mt10"></el-checkbox>
            <el-checkbox label="价格比预期高" class="mt10"></el-checkbox>
            <el-checkbox label="客户嫌麻烦" class="mt10"></el-checkbox>
            <el-checkbox label="超出业务范围" class="mt10"></el-checkbox>
            <el-checkbox label="没有时间" class="mt10"></el-checkbox>
            <el-checkbox label="其它" class="mt10"></el-checkbox>
          </el-checkbox-group>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入其它内容"
            v-model="canclereason.reason"
            style="width: 290px"
            class="mt10"
            v-if="canclereason.qita"
          >
          </el-input>
        </div>
        <div v-if="orderstate < 7 && (4 < orderstate || 4 == orderstate)">
          <div class="flex_center flex_items pb20 bold f18">退单原因</div>
          <el-checkbox-group
            v-model="cancles"
            class="flex_column"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox label="对益鸟不满意" class="mt10"></el-checkbox>
            <el-checkbox label="觉得价格高了" class="mt10"></el-checkbox>
            <el-checkbox label="暂时不处理了" class="mt10"></el-checkbox>
            <el-checkbox label="其它" class="mt10"></el-checkbox>
          </el-checkbox-group>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入其它内容"
            v-model="canclereason.reason"
            style="width: 290px"
            class="mt10"
            v-if="canclereason.qita"
          >
          </el-input>
          <el-input
            v-model="canclereason.money"
            placeholder="请输入退款金额"
            class="mt10"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false" plain size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="cancleok()" plain size="small"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div v-if="dialogTableVisible">
      <el-dialog :visible.sync="dialogTableVisible" width="60%">
        <div v-loading="loading">
          <div class="flex_between bbe9s">
            <div class="title f-left p10">任务信息</div>
            <div class="flex_start">
              <div class="flex_start mr20">
                <el-button
                  v-if="wiahulema2"
                  type="success"
                  plain
                  size="medium"
                  @click="waihu('dian')"
                  >外呼{{ waihudian.username }}店长</el-button
                >
                <el-button v-else-if="!wiahulema2" type="danger" size="medium"
                  >呼叫中</el-button
                >
                <el-button
                  v-if="wiahulema"
                  type="warning"
                  plain
                  size="medium"
                  @click="waihu"
                  >外呼客户</el-button
                >
                <el-button v-else-if="!wiahulema" type="danger" size="medium"
                  >呼叫中</el-button
                >
              </div>
              <div style="width: 53px">
                <span
                  class="f13 mt30 Shrink"
                  @click="Shrink(0)"
                  style="color: #aba9a9"
                  >{{ isindex[0].msg }}</span
                >
              </div>
            </div>
          </div>
          <!-- 信息内容 -->
          <div
            class="flex_column f14 bbe9s pb20 mt10"
            :style="{ display: isindex[0].tt == 't1' ? 'none' : 'flex' }"
          >
            <div class="p5">
              <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="8">
                  <div class="flex_start mr20 flex_items mt10">
                    <span class="mr10 t1sp">客户姓名:</span>
                    <span>{{ infos2.contacts }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="flex_start mr20 flex_items mt10">
                    <span class="mr10 t1sp">联系方式:</span>
                    <span>{{ infos2.telephone }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="flex_start mr20 flex_items mt10">
                    <span class="mr10 t1sp">房屋问题:</span>
                    <span v-if="infos2.problemTitle">{{
                      infos2.problemTitle
                    }}</span>
                    <span v-if="infos2.problemTitle1"
                      >、{{ infos2.problemTitle1 }}</span
                    >
                    <span v-if="infos2.problemTitle2"
                      >、{{ infos2.problemTitle }}</span
                    >
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="8">
                  <div class="flex_start mr20 flex_items mt10">
                    <span class="mr10 t1sp">订单来源:</span>
                    <span v-if="infos2.channelTitle != null"
                      >{{ infos2.channel }}-{{ infos2.channelTitle }}</span
                    >
                    <span v-else>{{ infos2.channel }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="flex_start mr20 flex_items mt10">
                    <span class="mr10 t1sp">下单时间:</span>
                    <span>{{ infos2.created_time }}</span>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="flex_start flex_items mt10">
                    <span class="mr10 t1sp">家庭地址:</span>
                    <div>{{ infos2.addres }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="flex_start flex_items p5">
              <span class="mr10 t1sp">备注信息:</span>
              <div style="width: 574px">{{ infos2.remarks }}</div>
            </div>
          </div>
          <div>
            <span
              class="f13 mt10 Shrink"
              @click="Shrink(1)"
              style="color: #aba9a9"
              >{{ isindex[1].msg }}</span
            >
          </div>
          <!-- 投诉详情 -->
          <div
            :style="{ display: isindex[1].tt == 't1' ? 'none' : 'flex' }"
            class="flex_warp"
          >
            <div v-if="pagename == 'tousu'">
              <div class="flex_between pb10 mt20 bbe9s">
                <div class="title f-left p10">投诉详情</div>
              </div>
              <div class="flex_warp f16 mt10 pb20">
                <div class="flex_start mr20 flex_items mt10">
                  <span class="mr10 t1sp">投诉时间</span>
                  <el-date-picker
                    :clearable="true"
                    v-model="isok.tousutime"
                    type="datetime"
                    placeholder="选择投诉时间"
                  ></el-date-picker>
                </div>
                <div class="flex_start flex_items mt10 m-r-60">
                  <span class="mr20 t1sp" style="width: 67px">投诉原因</span>
                  <el-select
                    v-model="isok.tousureason"
                    multiple
                    collapse-tags
                    placeholder="投诉原因"
                    style="width: 330px"
                  >
                    <el-option
                      v-for="item in tousureasons"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <div class="m-r-60 mt10 flex_warp flex_items">
                  <div class="mr20">
                    <span class="mr20 t1sp w-65">投诉店长</span>
                    <el-select
                      multiple
                      collapse-tags
                      v-model="isok.tousudian"
                      filterable
                      placeholder="请选择投诉店长"
                    >
                      <el-option
                        v-for="item in shoppers"
                        :key="item.user_id"
                        :label="item.usernamestorename"
                        :value="item.user_id"
                      ></el-option>
                    </el-select>
                  </div>
                  <div>
                    <span class="mr30 t1sp w-65">投诉师傅</span>
                    <el-select
                      multiple
                      collapse-tags
                      v-model="isok.tousushi"
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in shifus"
                        :key="item.id"
                        :label="item.username"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
            <!-- 售后详情 -->
            <div v-if="pagename == 'shouhou'" style="width: 100%">
              <div class="flex_between pb10 mt20 bbe9s">
                <div class="title f-left p10">售后详情</div>
              </div>
              <div class="flex_warp f16 mt20 pb20">
                <div class="flex_start mr20 flex_items mt10">
                  <span class="mr10 t1sp">售后时间</span>
                  <el-date-picker
                    :clearable="true"
                    v-model="isok.shouhoutime"
                    type="datetime"
                    placeholder="选择售后时间"
                  ></el-date-picker>
                </div>
                <div class="flex_start flex_items mt10 m-r-60">
                  <span class="mr20 t1sp w-65">售后原因</span>
                  <el-select
                    v-model="isok.shouhoureason"
                    collapse-tags
                    placeholder="售后原因"
                    class="w-330"
                    @change="selectshouhou"
                  >
                    <el-option
                      v-for="item in shishoureasons"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- 售后备注 -->
            <div
              v-if="pagename == 'shouhou'"
              class="flex_start flex_items m-r-60 mt20"
            >
              <span class="mr20 remark">售后备注</span>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="售后项目简介"
                v-model="shoubei"
              ></el-input>
            </div>
            <!-- 客户要求 -->
            <div class="flex_start flex_items m-r-60 mt20">
              <span class="mr20 remark">客户要求</span>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入客户要求"
                v-model="isok.kehuyaoqiu"
              ></el-input>
            </div>
            <!-- 问题图片 -->
            <div class="flex_start flex_items m-r-60 mt20">
              <span class="mr20 t1sp w-80">问题图片</span>
              <el-upload
                drag
                action="https://public.yiniao.co/api/v1/upload"
                list-type="picture-card"
                :multiple="mutrue"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="upsuccess"
                :data="tuzhi"
              >
                <i class="el-icon-plus"></i>
              </el-upload>

              <el-dialog :visible.sync="tudiag" :modal="false">
                <img width="100%" :src="completionUpload.dialogImageUrl" alt />
              </el-dialog>
            </div>
          </div>
          <!-- 提交 -->
          <div v-if="pagename == 'tousu'" class="m-t-30">
            <el-button
              class="tijiao"
              plain
              size="small"
              type="primary"
              @click="tijiao"
              >生成投诉</el-button
            >
          </div>
          <div v-if="pagename == 'shouhou'" class="m-t-30">
            <el-button
              class="tijiao"
              plain
              size="small"
              type="primary"
              @click="shouhou"
              >生成售后</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 转派 -->
    <div v-if="dialogTableVisible2" class="zhuanzhuan">
      <el-dialog :visible.sync="dialogTableVisible2" width="45%">
        <div class="title f-left plr10 pb10 bbe9s">转派订单</div>
        <!-- 转派店长 -->
        <div class="flex_items mt10 pos" v-loading="loading">
          <span class="mr20" style="margin-left: 20%">转派店长</span>
          <el-input
            v-model="shopper"
            placeholder="选择转派店长"
            @focus="getdian"
            class="mr20 w-300 h-35"
          ></el-input>
          <div
            v-if="diankuang && NewItems2.length != 0"
            class="abs diankuang manager"
          >
            <el-table :data="NewItems2" border @row-click="chosedian">
              <el-table-column
                prop="usernamestorename"
                label="店长"
              ></el-table-column>
              <el-table-column prop="work_tag" label="标签"></el-table-column>
            </el-table>
          </div>
          <p
            class="zanwudian"
            v-if="NewItems2.length == 0 && nodiankuang"
            @click="chosezanwu"
          >
            --暂无数据--
          </p>
          <el-button
            type="primary"
            class="addSubmit"
            plain
            size="small"
            @click="addsub('yes')"
            >转派订单</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 完工回访订单 -->
    <el-dialog
      title="任务完成"
      :visible.sync="completionVisible"
      width="50%"
      center
      :before-close="handleClose"
    >
      <el-form size="small" label-width="100px">
        <el-form-item label="回访方式">
          <el-checkbox-group v-model="completionForm.mode">
            <el-checkbox
              v-for="item in completionDialog.modes"
              :label="item"
              :key="item"
              >{{ item }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="施工评价">
          <el-rate
            v-model="completionForm.value1"
            style="margin-top: 6px"
          ></el-rate>
        </el-form-item>
        <el-form-item label="原因">
          <el-checkbox-group v-model="completionForm.checkList">
            <el-checkbox
              v-for="item in completionForm.value1 == 1
                ? completionDialog.checkBox1
                : completionForm.value1 == 2
                ? completionDialog.checkBox2
                : completionForm.value1 == 3
                ? completionDialog.checkBox3
                : completionForm.value1 == 4
                ? completionDialog.checkBox4
                : completionForm.value1 == 5
                ? completionDialog.checkBox5
                : []"
              :label="item"
              :key="item"
              >{{ item }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="店长评价" class="formItemMb">
          <el-rate
            v-model="completionForm.value2"
            style="margin-top: 6px"
          ></el-rate>
        </el-form-item>
        <el-form-item label="师傅评价" class="formItemMb">
          <el-rate
            v-model="completionForm.value3"
            style="margin-top: 6px"
          ></el-rate>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            :rows="4"
            placeholder="请输入备注内容"
            type="textarea"
            v-model="completionForm.textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="回访图片">
          <el-upload
            class="avatar-uploader"
            drag
            action="https://public.yiniao.co/api/v1/upload"
            list-type="picture-card"
            :multiple="completionUpload.mutrue"
            :on-preview="handlePicture"
            :on-success="upsus"
            :on-remove="remove"
            :file-list="completionUpload.fileList"
            :data="completionUpload.tuzhi"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="completionVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 商机池订单详情 -->
    <PoolInfo ref="PoolInfo" :orderPoolInfo="orderPoolInfo"></PoolInfo>
    <!-- 创建商机 -->
    <AddClueOrder ref="AddClueOrder"></AddClueOrder>
  </div>
</template>
<script>
import config from "@/util/config";
export default {
  props: ["near", "order_type", "order_num"],
  data() {
    return {
      state: "",
      //状态下拉数组
      stateList: [
        { id: 1, title: "放弃" },
        { id: 2, title: "派单" },
        { id: 3, title: "跟进" },
      ],
      PoolInfoDialog: false,
      //完工回访数据
      completionDialog: {
        modes: ["微信", "电话", "短信", "施工群", "其他"], //回访方式
        checkBox1: config.checkBox1,
        checkBox2: config.checkBox2,
        checkBox3: config.checkBox3,
        checkBox4: config.checkBox4,
        checkBox5: config.checkBox5,
      },
      completionVisible: false, //完工回访弹窗状态

      completionForm: {
        imageUrl: "", //回访图片
        mode: [], //回访方式
        value1: 5, //施工评价
        checkList: [], //原因
        value2: 5, //店长评价
        value3: 5, //师傅评价
        textarea: "", //备注
        order_id: "",
      },
      completionUpload: {
        // 图片上传
        mutrue: true,
        dialogImageUrl: "",
        tuzhi: { app_name: "public" },
        imgs: [],
        fileList: [],
      },

      loading: true,
      loading2: false,
      isdiag: false,
      dialogTableVisibleadd: false,
      dialogTableVisible2: false,
      bianjiorder: false,
      dialogVisible3: false,
      isheight: "",
      cancles: [],
      orderzhuangs: config.orderzhuangs, //订单状态
      clients: [],
      tasks: [],
      time1: "",
      chosecon: "",
      selectkind: "",
      orderzhuang: "",
      client: "",
      citys: [],
      genjin: [],
      jiedan: "",
      citykind: "",
      taskid: "",
      //   物业
      wuyeVal: "",
      wuyeid: "",
      wuyekuang: false,
      nowuye: false,
      items: [],
      // 分页数据
      currentPage3: 1,
      tableData: [],
      count: 0,
      //   投诉弹框
      dialogTableVisible: false,
      isindex: [
        {
          tt: "-1",
          msg: "--收起--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
      ],
      infos2: {},
      tousureasons: [
        "偷工减料质量差",
        "不专业，方案给错误",
        "工期拖延、安排不紧凑",
        "服务态度差，沟通没耐心",
        "施工人员不准时不守时，不告知安排",
        "家里家具财产等遭受损失",
        "承诺未兑现",
        "引导走私单",
        "恶意增项",
      ],
      isok: {
        istong: 0,
        textarea: "",
        tousu: 1,
        tousureason: [],
        tousutime: "",
        tousudian: [],
        tousushi: [],
        kehuyaoqiu: "",
        shouhoureason: [],
        shouhoutime: "",
      },
      shishoureasons: [
        "卫生间不破砖快修",
        "卫生间满刷钢化膜",
        "明管安装",
        "整体防水拆除重做",
        "破墙查漏",
        "墙面整体翻新—修补型",
        "墙面整体翻新—铲除型",
        "墙面局部修补",
        "墙裙/墙板安装",
        "电路面板安装",
        "洁具安装",
        "墙地砖铺贴",
        "屋面渗漏维修",
        "窗缝、窗台渗漏维修",
        "地下室、外墙渗漏背水面处理",
        "其它",
      ],
      shoubei: "",
      shoubeis: [
        {
          zhi: 2,
          con: "在无人为破坏的情况下，出现密封胶剥落。因回填层内留有的余水引起的墙面返潮不在质保范围内；洁具造成的再次漏水不在质保范围内",
        },
        {
          zhi: 2,
          con: "在无人为破坏的情况下，出现密封胶剥落。因回填层内留有的余水引起的墙面返潮不在质保范围内；洁具造成的再次漏水不在质保范围内",
        },
        {
          zhi: 5,
          con: "在无人为破坏的情况下，出现管件堵塞和渗漏。",
        },
        {
          zhi: 5,
          con: "无人为破坏情况下出现的再次渗漏；因原水管未更换导致的水管渗漏不在质保范围内",
        },
        {
          zhi: 5,
          con: "无人为破坏的情况下，施工区域出现的再次渗漏",
        },
        {
          zhi: 1,
          con: "无人为破坏的情况下出现起皮、剥落；因原墙基层未施工导致的细小裂缝不在验收及质保范围内",
        },
        {
          zhi: 2,
          con: "在无人为破坏的情况下，出现起皮、裂缝、返碱现象",
        },
        {
          zhi: 0,
          con: "上色后产生的细微色差不在验收及质保范围内",
        },
        {
          zhi: 5,
          con: "在无人为破坏情况下，出现因安装造成的脱落，松动",
        },
        {
          zhi: 2,
          con: "在无人为破坏情况下，出现盒座松动",
        },
        {
          zhi: 2,
          con: "在无人为破坏情况下，出现因安装造成的堵塞、松动",
        },
        {
          zhi: 2,
          con: "在无人为破坏情况下，墙地砖出现空鼓，松动。施工默认墙面与地面不对缝；不同区域之间不对缝、新旧砖之间的平整度误差不在验收范围内。",
        },
        {
          zhi: 2,
          con: "因施工区域内施工质量问题造成的渗漏属于质保范围。非施工区域引起的渗漏不在质保范围内。因屋面违建造成的渗漏不在质保范围内。",
        },
        {
          zhi: 5,
          con: "因窗户型材质量不合格、新菜排水不畅造成的渗漏不在质保范围内",
        },
        {
          zhi: 2,
          con: "非施工区域造成的渗漏不在质保范围内",
        },
      ],
      shoppers: [],
      shopper: "",
      shopper2: "",
      shopperid: "",
      textarea: "",
      diankuang: false,
      nodiankuang: false,
      shifus: [],
      mutrue: true,
      dialogImageUrl: "",
      tudiag: false,
      tuzhi: { app_name: "public" },
      imgs: [],
      order_id: "",
      wiahulema: true,
      wiahulema2: true,
      jige: "",
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      waihudian: { username: "", mobile: "" },
      isSaveState: true,
      ExternalData: "",
      call: [],
      value: "",
      pagename: "add",
      qudao: "",
      qudaos: [],
      orderstate: "",
      canclereason: {
        reason: "",
        money: "",
        qita: false,
      },
      wechat: "",
      hardbound: "",
      place1: [],
      place2: [],
      place3: [],
      shengid: "",
      details: {
        province_id: "",
        city_id: "",
        county_id: "",
      },
      // orderMark:"",
      orderPooId: "",
      orderPoolInfo: {},
    };
  },
  mounted() {
    this.getcitys();
    //console.log(this.near, "传过来的near+++");
  },
  methods: {
    // 查看商机池订单详情
    seeInfo(row) {
      //console.log(row);
      this.orderPooId = row.orderPooId;

      this.util
        .get(this.HOST + "/OrderPool/info", {
          orderPoolId: this.orderPooId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.orderPoolInfo = res.data;
            this.$refs.PoolInfo.seeInfo("66666");
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    //编辑商机

    edit(e) {
      this.$refs.AddClueOrder.getInfo(e.orderPooId, 1);
    },

    //关闭弹窗
    handleClose() {
      //do  something
      //console.log("111111111");
      this.completionVisible = false;
    },
    //图片上传
    uploadFile(file) {
      this.formDate.append(file, file.file);
    },
    handlePicture(file) {
      this.completionUpload.dialogImageUrl = file.url;
    },
    upsus(response, file, fileList) {
      this.completionUpload.imgs.push(response.data.file.path);
    },
    remove(file, fileList) {
      for (var i = 0; i < this.imgs.length; i++) {
        if (this.completionUpload.imgs[i] == file.response.data.file.path) {
          this.completionUpload.imgs.splice(i);
        }
      }
    },
    //完工回访
    completion(row) {
      this.completionUpload.fileList = [];
      this.completionForm.order_id = row.order_id;
      this.completionVisible = true;
      this.completionUpload.dialogImageUrl = "";
      this.completionUpload.imgs = [];
      this.completionForm.imageUrl = "";
      this.completionForm.mode = [];
      this.completionForm.value1 = 5;
      this.completionForm.checkList = [];
      this.completionForm.value2 = 5;
      this.completionForm.value3 = 5;
      this.completionForm.textarea = "";
    },
    //提交完工回访数据
    submit() {
      if (this.completionForm.mode.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择回访方式!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.completionForm.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: "请施工评价星级原因!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.completionForm.textarea == "") {
        this.$message({
          showClose: true,
          message: "请输入备注内容!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.completionForm.checkList.push(this.completionForm.textarea);

      this.util
        .post(this.HOST + "/Order/Visit", {
          mode: JSON.parse(JSON.stringify(this.completionForm.mode)).join(","),
          order_id: this.completionForm.order_id,
          score: this.completionForm.value1,
          score2: this.completionForm.value2,
          score3: this.completionForm.value3,
          tupian: JSON.parse(JSON.stringify(this.completionUpload.imgs)),
          content: JSON.parse(
            JSON.stringify(this.completionForm.checkList)
          ).join(","),
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.getLists();
            this.completionVisible = false;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
            this.finishDialog1 = false;
          }
        });
    },
    finishTask() {
      //任务完成处理模态框确认按钮
    },
    // 是否显示标记数据
    changeUsername(order_id, adminList) {
      let adminList2 = 0;
      if (adminList == "") {
        adminList2 = 1;
      }
      this.util
        .get(this.HOST + "/Cc/OrderHangUp", {
          order_id: order_id,
          hang_up: adminList2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "标记改变成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
      this.getDataAgain();
    },
    // 城市
    getcitys() {
      this.$loading(this.util.opload);
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.citys = res.data;
          this.getclient();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    getwhere(citykind) {
      this.wuyeVal = "";
      this.shopper2 = "";
      this.qudao = "";
      this.order_id = "";
      if (citykind) {
        this.util
          .get(this.HOST + "/Common/chanel", {
            city_id: this.citykind,
          })
          .then((res) => {
            if (res.code == 200) {
              console.log(res.data);
              this.qudaos = res.data;
              this.citys.map((item) => {
                if (citykind == item.city_id) {
                  this.shengid = item.province_id;
                }
              });
              // this.wuyes();
              this.shop("aa");
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
      }
    },
    // 获取物业
    wuyes() {
      this.items = [];
      this.util
        .get(this.HOST + "/Common/residential", {
          city_id: this.citykind,
          //uu: this.citykind,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.items = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长
    shop(aa) {
      this.shoppers = [];
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: this.citykind,
          order_id: this.order_id,
          storeManagerName: this.shopper,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取客服
    getclient() {
      this.util.get(this.HOST + "/Common/CustomerService", {}).then((res) => {
        if (res.code == 200) {
          this.clients = res.data;
          this.getLists();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 获取店长号码名字
    dianzhangphone(idx) {
      this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: idx,
        })
        .then((res) => {
          if (res.code == 200) {
            this.waihudian = res.data;
            this.tousukuang();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 列表
    getLists(fen) {
      if (fen) {
        this.$loading(this.util.opload);
      }
      if (this.time1 == null) {
        this.time1 = "";
      }
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      this.util
        .get(this.HOST + "/OrderPool/list", {
          start_time: time1,
          end_time: time2,
          type: 2,
          city: this.citykind,
          title: this.selectkind,
          contacts: this.chosecon,
          enter: this.client,
          page: this.currentPage3,
          limit: 20,
          types: this.order_num || "",
          source: this.order_type || "",
          chanel: this.qudao,
          state: this.state,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData = res.data.list;

            this.$store.commit("settask", 0);
            this.$loading().close();
            this.loading = false;
          } else {
            this.$loading().close();
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    tableRowStyleName({ row }) {
      if (row.tui_jian != 0) {
        return "cell-grey";
      }
    },
    changewechat(order_id, wechat) {
      let wechat2 = 0;
      if (wechat == true) {
        wechat2 = 1;
      }
      this.util
        .get(this.HOST + "/Order/SelectorSwitch", {
          order_id: order_id,
          wechat: wechat2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "开启成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleCurrentChange(val) {
      this.loading2 = true;
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      this.tableData = [];
      this.util
        .get(this.HOST + "/OrderPool/list", {
          start_time: time1,
          end_time: time2,
          type: 2,
          city: this.citykind,
          title: this.selectkind,
          contacts: this.chosecon,
          enter: this.client,
          page: this.currentPage3,
          limit: 20,
          types: this.order_num || "",
          source: this.order_type || "",
          chanel: this.qudao,
          state: this.state,
        })

        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData = res.data.list;
            // for (let i = 0; i < res.data.data.length; i++) {
            //   if (res.data.data[i].tui_jian != 0) {
            //     this.tableData[i].community = res.data.data[i].personal;
            //   }
            //   if (res.data.data[i].tui_jian != 0) {
            //     this.tableData[i].residential = res.data.data[i].residential;
            //   }
            //   if (res.data.data[i].wechat == 0) {
            //     this.tableData[i].wechat = false;
            //   } else {
            //     this.tableData[i].wechat = true;
            //   }
            // }
            this.loading2 = false;
          } else {
            this.loading2 = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 发起外呼
    waihu(aa) {
      if (!this.wiahulema || !this.wiahulema2) {
        this.$message({
          showClose: true,
          message: "不能同时呼叫!",
          type: "warning",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let phone = "";
      if (aa == "dian") {
        if (this.waihudian.username == "") {
          this.$message({
            showClose: true,
            message: "该订单暂未指派店长!",
            type: "warning",
            duration: 1200,
            offset: 90,
          });
          return false;
        } else {
          phone = this.waihudian.mobile;
        }
      } else {
        phone = this.waihudian.telephone;
      }
      // 打包后
      this.$jsonp(this.HOST4, {
        account: "N000000015324",
        appid: "ncvsorfw58bk2o6r",
        secret: "31792f00-d2e5-11e9-8be9-b3731625d6b2",
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
          this.kaihu(res.accessToken, phone, aa);
        }
      });
    },
    kaihu(token, phone, aa) {
      let timestamp = new Date().getTime();
      let good = "customer";
      if (aa == "dian") {
        good = "shopowner";
      }
      let kk = timestamp + "-" + good;
      // 打包后
      this.$jsonp(this.HOST5, {
        phonenum: phone,
        integratedid: sessionStorage.getItem("integratedid"),
        accessToken: token,
        account: "N000000015324",
        flag: 106,
        ExternalData: "main:" + kk,
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
        }
      });
    },
    selectshouhou(val) {
      if (val == "其它") {
        this.shoubei = "";
      } else {
        for (let i = 0; i < this.shishoureasons.length; i++) {
          if (this.isok.shouhoureason == this.shishoureasons[i]) {
            this.shoubei =
              "质保" +
              this.shoubeis[i].zhi +
              "年" +
              "---" +
              this.shoubeis[i].con;
          }
        }
      }
    },
    Popupbox(con, row) {
      this.$refs.AddClueOrder.showDialog();
    },
    // 店长
    getdian(event) {
      this.diankuang = true;
      this.nodiankuang = true;
      this.isheight = this.util.gaodu() + "px";
    },
    chosedian(row) {
      this.shopperid = row.user_id;
      this.shopper = row.usernamestorename;
      this.diankuang = false;
      this.nodiankuang = false;
      this.isheight = "0px";
    },
    getwuye(event) {
      this.wuyekuang = true;
      this.nowuye = true;
      this.isheight = this.util.gaodu() + "px";
    },
    outwuye() {
      this.wuyekuang = false;
      this.nowuye = false;
      this.diankuang = false;
      this.nodiankuang = false;
      this.isheight = "0px";
    },
    chosewuye(idx, event) {
      this.wuyeid = this.NewItems[idx].group_id;
      this.wuyeVal = this.NewItems[idx].username;
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    closewuye() {
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    chosezanwu() {
      this.nowuye = false;
      this.nodiankuang = false;
    },
    getDataAgain(e) {
      this.order_type = "";
      this.order_num = "";
      if (this.time1 == null) {
        this.time1 = "";
      }
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      this.loading2 = true;
      this.tableData = [];
      this.currentPage3 = 1;
      this.wuyekuang = false;
      if (this.chosecon == "") {
        this.selectkind = "";
      }
      this.util
        .get(this.HOST + "/OrderPool/list", {
          start_time: time1,
          end_time: time2,
          type: 2,
          city: this.citykind,
          title: this.selectkind,
          contacts: this.chosecon,
          enter: this.client,
          page: this.currentPage3,
          limit: 20,
          types: e == 0 ? "" : this.order_type,
          source: e == 0 ? "" : this.order_num,
          chanel: this.qudao,
          state: this.state,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData = res.data.list;
            // for (let i = 0; i < res.data.data.length; i++) {
            //   if (res.data.data[i].tui_jian != 0) {
            //     this.tableData[i].community = res.data.data[i].personal;
            //   }
            //   if (res.data.data[i].tui_jian != 0) {
            //     this.tableData[i].residential = res.data.data[i].residential;
            //   }
            //   if (res.data.data[i].wechat == 0) {
            //     this.tableData[i].wechat = false;
            //   } else {
            //     this.tableData[i].wechat = true;
            //   }
            // }
            this.loading2 = false;
          } else {
            this.loading2 = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 投诉框
    but(idx, kind) {
      //console.log(idx.order_id);
      this.order_id = idx.order_id;
      this.isok.tousutime = "";
      this.isok.tousureason = [];
      this.isok.tousudian = [];
      this.isok.tousushi = [];
      if (kind == "tousu") {
        if (idx.complaint) {
          this.$message({
            showClose: true,
            type: "error",
            message: "该订单已投诉!",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
        this.pagename = kind;
        this.dialogTableVisible = true;
        // this.loading = true;
        this.citykind = "";
        this.dianzhangphone(idx.order_id);
        // this.tousukuang();
      } else if (kind == "zhipai") {
        this.dialogTableVisible2 = true;
        this.shopper = "";
        this.shopperid = "";
        this.shop("aa");
      } else if (kind == "bianji") {
        switch (idx.house_id) {
          case null:
            idx.cc_id = "0";
            sessionStorage.setItem("kind", "bianji");
            this.$store.commit("setOrder", idx);
            this.$store.commit("setMessage", true);
            this.pagename = "bianji";
            this.isdiag = true;
            this.dialogTableVisibleadd = true;
            break;
          case "":
            idx.cc_id = "0";
            sessionStorage.setItem("kind", "bianji");
            this.$store.commit("setOrder", idx);
            this.$store.commit("setMessage", true);
            this.pagename = "bianji";
            this.isdiag = true;
            this.dialogTableVisibleadd = true;
            break;
          default:
            idx.cc_id = "0";
            sessionStorage.setItem("kind", "bianjiNew");
            this.$store.commit("setOrder", idx);
            this.$store.commit("setMessage", true);
            this.pagename = "bianjiNew";
            this.isdiag = true;
            this.dialogTableVisibleadd = true;
            break;
        }
      } else if (kind == "shouhou") {
        this.pagename = kind;
        this.dialogTableVisible = true;
        // this.loading = true;
        this.dianzhangphone(idx.order_id);
      } else if (kind == "quxiao") {
        this.$store.commit("setOrder", idx);
        this.orderstate = this.$store.state.orderstate;
        this.canclereason.qita = false;
        this.canclereason.reason = "";
        this.cancles = [];
        this.canclereason.reason = "";
        this.canclereason.money = "";
        this.isdiag = true;
        this.dialogVisible3 = true;
      } else if (kind == "pool") {
        this.util
          .get(this.HOST + "/Order/cluePool", {
            order_id: this.order_id,
          })
          .then((res) => {
            this.$message({
              showClose: true,
              message: res.msg,
            });
          });
      } else {
        this.$store.commit("setOrder", idx);
        this.$store.commit("setMessage", true);
        this.pagename = kind;
        this.isdiag = true;
        this.dialogTableVisibleadd = true;
      }
    },
    // 取消提交
    handleCheckedCitiesChange(val) {
      let arr = 0;
      for (let i = 0; i < val.length; i++) {
        if (val[i] == "其它") {
          this.canclereason.qita = true;
          break;
        } else {
          arr += 1;
        }
      }
      if (arr == val.length) {
        this.canclereason.qita = false;
        this.canclereason.reason = "";
      }
    },
    cancleok() {
      let regPos = /^[0-9]+.?[0-9]*/; //判断是否是数字。
      if (
        !regPos.test(this.canclereason.money) &&
        this.orderstate < 7 &&
        (this.orderstate == 4 || this.orderstate > 4)
      ) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入正确金额",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.cancles.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择至少一项原因",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "任务处理中...",
      });
      let reason = this.cancles.toString(",");
      if (this.canclereason.reason != "") {
        reason = reason + "," + this.canclereason.reason;
      }
      let state = 9;
      if (this.$store.state.orderstate < 4) {
        state = 8;
      }
      if (this.orderstate < 4) {
        this.canclereason.money = 0;
      }
      this.util
        .get(this.HOST + "/Order/Del", {
          state: state,
          tui_moey: this.canclereason.money,
          reason: reason,
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "操作成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.dialogVisible3 = false;
              this.$store.commit("settask", 1);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    diagopen() {
      this.$store.commit("setMessage", true);
    },
    diagclose() {
      this.$store.commit("setMessage", false);
    },
    // 转派订单
    addsub() {
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "订单转派中...",
      });
      this.util
        .post(this.HOST + "/Order/Transfer", {
          order_id: this.order_id,
          user_id: this.shopperid,
          sms1: 1,
          sms: 0,
          type: 2,
          suibian: 0,
          problemArr: "",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "订单转派成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("settask", 1);
              this.dialogTableVisible2 = false;
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    tousukuang() {
      this.util
        .get(this.HOST + "/Order/info", {
          order_id: this.order_id,
          Colloquialism: 4,
        })
        .then((res) => {
          if (res.code == 200) {
            // this.infos = res.data;
            this.infos2 = res.data;
            this.isok.tousutime = this.util.intertime(this.util.getnow());
            this.shop();
            this.teacher();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },

    // 获取师傅
    teacher() {
      this.util
        .get(this.HOST + "/Common/getMaster", {
          order_id: this.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shifus = res.data;
            this.loading = false;
            // this.getLists();
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },

    handleRemove(file, fileList) {
      for (var i = 0; i < this.imgs.length; i++) {
        if (this.imgs[i] == file.response.data.file.path) {
          this.imgs.splice(i);
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.tudiag = true;
    },
    upsuccess(response, file, fileList) {
      // //console.log(response);
      this.imgs.push(response.data.file.path);
    },
    // 收缩
    Shrink(num) {
      if (this.isindex[num].tt == "t1") {
        this.isindex[num].tt = "-1";
        this.isindex[num].msg = "--收起--";
      } else {
        this.isindex[num].tt = "t1";
        this.isindex[num].msg = "--展开--";
      }
    },

    // 生成投诉
    tijiao() {
      let tousutime = this.isok.tousutime;
      if (
        this.util.datatime(tousutime) == "NaN-NaN-NaN NaN:NaN:NaN" &&
        this.isok.tousu == 1
      ) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入投诉时间",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.isok.tousu == 1 && this.isok.tousureason.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择投诉原因",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (
        this.isok.tousu == 1 &&
        this.isok.tousudian.length == 0 &&
        this.isok.tousushi.length == 0
      ) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择投诉店长或者师傅!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "投诉生成中...",
      });
      this.util
        .post(this.HOST + "/Order/Tou", {
          reason: this.isok.tousureason.toString(","),
          shopowner: this.isok.tousudian.toString(","),
          masterworker: this.isok.tousushi.toString(","),
          picture: JSON.stringify(this.imgs)
            .replace(/\"/g, "")
            .replace(/\[|]/g, ""),
          requirement: this.isok.kehuyaoqiu,
          create_time: this.util.datatime(tousutime),
          order_id: this.order_id,
          Call_record: this.call,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "生成投诉成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("settask", 1);
              this.dialogTableVisible = false;
              this.isok = {};
              (this.isok.istong = 0),
                (this.isok.textarea = ""),
                (this.isok.tousu = 1),
                (this.isok.tousureason = []),
                (this.isok.tousutime = ""),
                (this.isok.tousudian = []),
                (this.isok.tousushi = []),
                (this.isok.kehuyaoqiu = ""),
                (this.isok.shouhoureason = []),
                (this.isok.shouhoutime = ""),
                (this.imgs = []);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 生成售后
    shouhou() {
      let shouhoutime = this.isok.shouhoutime;
      if (
        this.util.datatime(shouhoutime) == "NaN-NaN-NaN NaN:NaN:NaN" &&
        this.isok.tousu == 1
      ) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入售后时间",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.isok.shouhoureason.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择售后原因",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "售后生成中...",
      });
      this.util
        .post(this.HOST + "/Order/AfterSales", {
          reason: this.isok.shouhoureason.toString(","),
          picture: JSON.stringify(this.imgs)
            .replace(/\"/g, "")
            .replace(/\[|]/g, ""),
          requirement: this.isok.kehuyaoqiu,
          create_time: this.util.datatime(shouhoutime),
          order_id: this.order_id,
          notereason: this.shoubei,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "生成售后成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("settask", 1);
              this.dialogTableVisible = false;
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
  // 物业
  computed: {
    NewItems() {
      var _this = this;
      var NewItems = [];
      this.items.map(function (item) {
        if (item.username.search(_this.wuyeVal) != -1) {
          NewItems.push(item);
        }
      });
      return NewItems;
    },
    // 店长
    NewItems2() {
      var _this = this;
      var NewItems2 = [];
      this.shoppers.map(function (item) {
        if (item.usernamestorename.search(_this.shopper) != -1) {
          NewItems2.push(item);
        }
      });
      return NewItems2;
    },
    // 判断弹出框
    getStoreItem() {
      this.dialogTableVisibleadd = this.$store.state.dialogTableVisible;
      this.bianjiorder = this.$store.state.dialogTableVisible;
      this.isdiag = this.$store.state.dialogTableVisible;
    },
    // 判断处理成功
    getStoreTask() {
      if (this.$store.state.task == 1) {
        this.tableData = [];
        // this.currentPage3 = 1;
        this.getLists("fen");
        // this.getDataAgain()
      }
    },
    // 转换时间
    reverseTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
  watch: {
    getStoreTask() {},
    getStoreItem() {},
  },
  components: {
    Add: () => import("@/components/addorder.vue"),
    Detailes2: () => import("@/components/detailes2.vue"),
    Assign: () => import("@/components/Assign.vue"),
    AssignNew: () => import("@/components/AssignNew.vue"),
    PoolInfo: () => import("@/components/orderPoolInfo.vue"),
    AddClueOrder: () => import("@/components/addClueOrder.vue"),
  },
};
</script>
<style>
.box-card {
  margin-bottom: 30px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.wuyekuang {
  top: 35px;
  left: 86px;
}
.BBg {
  width: 100%;
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
}
.orderfinding {
  margin: 0 auto;
  background: white;
  padding: 0 30px 30px 35px;
  border-radius: 8px;
  z-index: -99;
}
/* 物业 */
.wuyekuang {
  position: absolute;
  top: 34px;
  left: 75px;
  width: 170px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.wuyekuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  /* position:absolute; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 20000;
}
.wuyekuang li:hover {
  background-color: #f5f7fa;
}
/* 店长框 */
.zhuanzhuan .diankuang {
  top: 46px;
  left: 76px;
  width: 204px;
  list-style: none;
  margin-left: 30%;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.diankuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.diankuang li:hover {
  background-color: #f5f7fa;
}
.zhuanzhuan .zanwudian {
  padding: 10px 0;
  margin-left: 30%;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 50px;
  left: 76px;
  width: 204px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.cell-grey {
  background: #e0e0e0;
}
.username {
  font-size: 12px;
  color: #999;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.el-table {
  width: 100%;
}
.el-table__header-wrapper table,
.el-table__body-wrapper table {
  width: 100% !important;
}
.el-table__body,
.el-table__footer,
.el-table__header {
  table-layout: auto;
}
.littleBox input {
  /* width: 100px!important; */
  height: 32px !important;
  line-height: 32px !important;
}
.datePicker {
  width: 256px !important;
  height: 32px !important;
  line-height: 32px !important;
}
.orderList {
  overflow-y: scroll;
  white-space: nowrap;
  width: 100%;
}
.filterPr {
  width: 100%;
  min-width: 600px;
}
.lookMsg {
  color: #66b1ff;
  cursor: pointer;
}
.remark {
  width: 77px;
  color: #3f51b5;
}
.manager {
  width: 420px;
  left: -35px;
  top: 65px;
}
.addSubmit {
  margin: 10px auto;
}
</style>
